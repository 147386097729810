<template>
	<div class="u-page">
		<MoleculesAdSlot ad_id="sky-left" ad_unit_small="" :ad_unit_large="showAds.getShowAds() ? 'd_sky_left' : ''" />

		<div class="u-content-area">
			<HeaderWrapper />

			<MoleculesAdSlot ad_id="superbanner" ad_unit_small="" :ad_unit_large="showAds.getShowAds() ? 'd_billboard' : ''"  />

			<slot />

			<BreadcrumbWrapper />

			<FooterWrapper></FooterWrapper>
		</div>

		<MoleculesAdSlot ad_id="sky-right" ad_unit_small="" :ad_unit_large="showAds.getShowAds() ? 'd_sky_right' : ''" />
	</div>
</template>

<script lang="ts" setup>
import { useShowAds } from '~/composables/useShowAds';

const showAds = await useShowAds();
</script>
